import BaseMixin from './base.mixin'
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'

const mixin = {
  mixins: [BaseMixin, LocalStorageMixin, AuthMixin],
  methods: {
    async getRadioExamsList () {
      try {
        const response = await this.requestGet(`/core/FastReport`)
        if (response && response.data) {
          return response.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        throw new Error('Erro ao buscar lista de exames: ' + error.message)
      }
    },

    async examRepeatRadio (examId, reason) {
      try {
        const response = await this.requestPost(`/core/FastReport/${examId}/Repeat`, {
          reason
        })

        if (response && response.data) {
          return response.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        console.error('Erro ao repetir o exame:', error)
        throw new Error('Erro ao repetir o exame: ' + error.message)
      }
    },

    async getExamNotes (examId) {
      try {
        const response = await this.requestGet(`/core/FastReport/${examId}/Notes`)
        if (response && response.data) {
          return response.data.notes
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        throw new Error('Erro ao buscar relatório do exame: ' + error.message)
      }
    },

    async sendReportRadio (examId, payload) {
      try {
        const response = await this.requestPost(`/core/FastReport/${examId}/Report`, payload, {
          headers: { 'Content-Type': 'application/json' }
        })
        return response
      } catch (error) {
        console.error('Erro ao enviar o relatório:', error.response?.data || error.message)
        throw new Error(
          'Erro ao enviar o relatório: ' + (error.response?.data?.errors[0]?.message || 'Erro desconhecido')
        )
      }
    },
    async getUrgencyAlert (examId) {
      try {
        const response = await this.requestGet(`/core/Exams/${examId}`)
        console.log('Resposta completa da API:', response)
        if (response && response.data && typeof response.data.hasUrgencyAlert === 'boolean') {
          return response.data.hasUrgencyAlert
        } else {
          console.error('Estrutura inesperada na resposta da API:', response)
          return false
        }
      } catch (error) {
        console.error('Erro ao buscar alerta de urgência:', error.message)
        return false
      }
    }
  }
}

export default mixin
